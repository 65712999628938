







import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
@Component({})
export default class MyNull extends Mixins(Mixin) {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public img!: string;
}
