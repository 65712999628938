import { render, staticRenderFns } from "./ExternalWriteCommentPage.vue?vue&type=template&id=6198bc06&"
import script from "./ExternalWriteCommentPage.vue?vue&type=script&lang=ts&"
export * from "./ExternalWriteCommentPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports